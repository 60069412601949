import React, {Component, useState} from 'react';
import './App.scss';
import Home from './components/home/home';
import Jewelry from './components/jewelry/jewelry';
import StorePolicy from './components/storepolicy/storePolicy';
import ContactUs from './components/contactus/contactus';
import Links from './components/links/links';
import Garments from './components/garments/garments';
import ReligiousItems from './components/religiousitems/religiousitems';
import Handicrafts from './components/handicrafts/handicrafts';
import IncenseOils from './components/incenseoils/incenseoils';
import SingingBowls from './components/singingbowls/singingbowls';
import Books from './components/books/books';
import Antiques from './components/antiques/antiques';
import Sidebar from "./components/header/sidebar/sidebar";
import MeditationRoom from './components/meditationroom/meditationRoom';
import { connect } from 'react-redux';


import {Route, Switch, BrowserRouter as Router, Link} from 'react-router-dom'
import Navbar from "./components/header/navbar";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import one from "./assets/img/jewelry/bangles/1.jpg";
import two from "./assets/img/jewelry/bangles/2.jpg";
import four from "./assets/img/jewelry/beads/2.jpg";
import five from "./assets/img/jewelry/earRings/1.jpg";
import six from "./assets/img/jewelry/earRings/2.jpg";
import seven from "./assets/img/jewelry/fingerRings/1.jpg";
import eight from "./assets/img/jewelry/fingerRings/2.jpg";
import nine from "./assets/img/jewelry/necklace/1.jpg";
import ten from "./assets/img/jewelry/necklace/2.jpg";
import eleven from "./assets/img/jewelry/pendants/1.jpg";
import twelve from "./assets/img/jewelry/pendants/2.jpg";
import thirteen from "./assets/img/antiques/1.jpg";
import fourteen from "./assets/img/antiques/2.jpg";
import fifteen from "./assets/img/singingbowls/crystal/1.jpg";
import sixteen from "./assets/img/singingbowls/crystal/2.jpg";
import twenty from "./assets/img/singingbowls/traditionaltibetan/3.jpg";
import tone from "./assets/img/religiousitems/thangkas/1.jpg";
import ttwo from "./assets/img/religiousitems/thangkas/2.jpg";
import tthree from "./assets/img/religiousitems/statues/1.jpg";
import tfour from "./assets/img/religiousitems/statues/2.jpg";
import tfive from "./assets/img/religiousitems/prayerbeads/1.jpeg";
import tsix from "./assets/img/religiousitems/prayerbeads/2.jpeg";

import logo from './assets/img/logo.png';
import logoRight from './assets/img/common/word-right.png';
import logoLeft from './assets/img/common/word-left.png';
import stupa from './assets/img/common/stupa1.jpg';
import meditation from './assets/img/common/meditation.png';
import SpecialEvents from "./components/specialevents/specialevents";
import TibetanCarpets from "./components/tibetancarpets/tibetancarpets";
import StoreInfo from './components/storeinfo/storeinfo';
import WholeSale from './components/wholesale/wholesale';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
};
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }
    render() {
        return (
            <Router>
                <div className="app-content">
                    <div className={'fixed-top-section'}>
                        <div className="d-inline-block w-100">
                            <div className="row mx-0 lob-bg-light top-section pt-md-0 pt-1">
                                <div className="col-5 px-0">
                                    <Link className="navbar-brand color-white py-0" to="/" style={{zIndex:2, position: 'absolute', marginTop: '10px'}}>
                                        <img src={logo} className={'logo mr-2 ml-md-4'} alt="logo"/>
                                        <div className="d-inline-block brand-name">
                                            <span><span className='bigger'>L</span>AND <span className='bigger'>O</span>F <span className='bigger'>B</span>UDDHA</span>
                                        </div>
                                        <div className="logo-footer p-1" style={{marginTop: '10px'}}>
                                            <small className='d-inline-block text-center w-100 color-maroon'>
                                                <img className="pull-left logo-left" src={logoLeft} alt="right" style={{width: '65px'}}/>
                                                <span className="sub-header">Himalayan Gift Shop <i className={'fa fa-circle dot'}></i>Wholesale & Retail</span>
                                                <img className="pull-right logo-right" src={logoRight} alt="right" style={{width: '65px'}}/>
                                            </small>
                                    
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-2 text-center">
                                    {/* <img src={stupa} className="stupa" alt="stupa" /> */}
                                </div>
                                <div className="col-5 text-right color-white pl-0 pr-2 px-md-4">
                                    <div className="row-inline">
                                        <a title="Email Land Of Buddha" href="mailto:sales@lobny.com"><i
                                            className="reg-fa fa fa-envelope-square color-white pl-4" aria-hidden="true"></i></a>
                                        <a href="mailto:sales@lobny.com" className={'color-white ml-2'}>sales@lobny.com</a>
                                    </div>
                                    <div className="row-inline">
                                        <a title="Call Land Of Buddha" href="tel:646-602-6588"><i
                                            className="reg-fa fa fa-phone-square color-white pl-4" aria-hidden="true"></i></a>
                                        <a href="tel:646-602-6588" className={'color-white ml-2'}>(646)-602-6588</a>
                                        <a href="tel:917-675-6331" className={'color-white ml-3'}>(917)-675-6331</a>
                                    </div>
                                    <div className="row-inline">
                                        <a title="Instagram" href="https://www.instagram.com/landofbuddha/?hl=en"
                                        className={'color-white pl-3'} target="_blank">
                                            <i className="reg-fa fa fa-instagram" aria-hidden="true"></i>
                                        </a>
                                        <a title="Facebook" href="https://www.facebook.com/Land-of-Buddha-100503576677749/"
                                        className={'color-white pl-3'} target="_blank">
                                            <i className="reg-fa fa fa-facebook" aria-hidden="true"></i>
                                        </a>
                                        <a title="Twitter" href="https://twitter.com/landofbuddha"
                                        className={'color-white pl-3'} target="_blank">
                                            <i className="reg-fa fa fa-twitter" aria-hidden="true"></i>
                                        </a>
                                        <a title="Yelp" href="https://www.yelp.com/biz/land-of-buddha-new-york"
                                        className={'color-white pl-3'} target="_blank">
                                            <i className="reg-fa fa fa-yelp" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <Navbar/>
                        </div>
                        <div className='row mx-0 border-lob bg-light-grey'>
                            <div className='col-lg-3 col-4 singing-bowl-header p-1'>
                                <Link className='text-center border rounded bg-light p-1' to={'/singing-bowls'} onClick={() => this.props.activeHeaderChanged('singingBowls')} style={{width: '30%'}}>
                                    <label className='cursor-pointer color-dark'>Singing bowls</label>
                                    <img className="sb" src={twenty}/>
                                </Link>
                                <Link className='text-center border rounded bg-light p-1' to={'/meditation-room'} onClick={() => this.props.activeHeaderChanged('meditation-room')} style={{width: '57%'}}>
                                    <img src={meditation} alt="meditation" style={{width: '27%'}}/>
                                    <label className='mb-0 cursor-pointer color-dark'>Visit Our Meditation Room</label>
                                </Link>
                            </div>
                            <div className='bw-2 col-lg-6 col-4 border-right border-left color-grey px-0'>
                                <Carousel className={'lob-carousel m-auto bg-dark'}
                                          arrows={false}
                                          swipeable={true}
                                          responsive={responsive}
                                          infinite={true}
                                          autoPlay
                                          autoPlaySpeed={3000}
                                          keyBoardControl={true}
                                          customTransition="all .5"
                                          containerClass="carousel-container"
                                          dotListClass="custom-dot-list-style"
                                          itemClass="carousel-item-padding-40-px"
                                          slidesToSlide={5}
                                >
                                    <div>
                                        <img alt="carousel" src={one}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={two}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={four}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={five}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={six}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={seven}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={eight}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={nine}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={ten}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={eleven}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={twelve}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={thirteen}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={fourteen}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={fifteen}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={sixteen}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={tone}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={ttwo}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={tthree}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={tfour}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={tfive}/>
                                    </div>
                                    <div>
                                        <img alt="carousel" src={tsix}/>
                                    </div>
                                </Carousel>
                            </div>
                            <div className='col-lg-3 col-4 px-1'>
                                <div className="p-1 mailing-list h-100">
                                    <div className='contantContentForm border rounded bg-light p-2'>
                                        <label>Join Our Mailing List</label>
                                        <form className="form-group" name="ccoptin" action="http://visitor.r20.constantcontact.com/d.jsp" target="_blank" method="post">
                                            <input type="hidden" name="llr" value="7cmitukab" />
                                            <input type="hidden" name="m" value="1110940332124" />
                                            <input type="hidden" name="p" value="oi" />
                                            <input className="form-control email-cc pull-left" type="text" name="ea" size="20" placeholder="Email" />
                                            <button type="submit" name="go" value="Go" className="join-btn btn btn-sm btn-secondary pull-right">Join</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/*<p className={'lob-carousel-label mb-0 text-center lob-bg-light py-2'}>*/}
                            {/*    <Link to={this.props.activeCarousel.link}>{this.props.activeCarousel.label}*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div className="row mx-0 main-section">
                        <div className="col-md-2 col-2 px-0">
                            <Sidebar/>
                        </div>
                        <div className="col-md-10 col-10 px-0">
                            <div id={'main'} className={'bg-light'}>
                                <Switch>
                                    <Route exact path="/" component={Home}/>
                                    <Route exact path="/home" component={Home}/>
                                    <Route exact path="/TibetanStoreNYC.html" component={Home}/>
                                    <Route exact path="/jewelry" component={Jewelry}/>
                                    <Route exact path="/store-policy" component={StorePolicy}/>
                                    <Route exact path="/contact-us" component={ContactUs}/>
                                    <Route exact path="/links" component={Links}/>
                                    <Route exact path="/garments" component={Garments}/>
                                    <Route exact path="/religious-items" component={ReligiousItems}/>
                                    <Route exact path="/handicrafts" component={Handicrafts}/>
                                    <Route exact path="/incense-oils" component={IncenseOils}/>
                                    <Route exact path="/singing-bowls" component={SingingBowls}/>
                                    <Route exact path="/special-events" component={SpecialEvents}/>
                                    <Route exact path="/books" component={Books}/>
                                    <Route exact path="/antiques" component={Antiques}/>
                                    <Route exact path="/meditation-room" component={MeditationRoom}/>
                                    <Route exact path="/tibetan-carpets" component={TibetanCarpets}/>
                                    <Route exact path="/store-info" component={StoreInfo}/>
                                    <Route exact path="/wholeSale" component={WholeSale}/>
                                </Switch>
                            </div>
                        </div>
                    
                    </div>
                    <div id={'footer'} className="color-gold lob-bg-light py-3">
                        <p className="text-center mb-0">Copyright © <span>{new Date().getFullYear()}</span>, Land Of Buddha.</p>
                    </div>
                </div>
            </Router>
        );
    }
}

const mapStateToProps = (state) => {
  return {
      activeHeader: state.activeHeader,
      activeCarousel: state.activeCarousel
  }
};
const mapDispatchToProps = (dispach) => {
    return {
        activeHeaderChanged: (value) => dispach({type: 'activeHeader', value: value}),
        activeCarouselChanged: (value) => dispach({type: 'activeCarousel', value: value})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);