import React, { Component } from "react";
import CommonUtils from "../../utils/common";

export default class StorePolicy extends Component {
    render () {
        return <div id={'store-policy'} className={'p-md-4 p-1'}>
            <h5 className={CommonUtils.pageHeaderClassName}>Store Policy</h5>
            <h6 className={'text-uppercase color-maroon'}>Shipment</h6>
            <p>
                Shipping and handling charges are extra, and are based on the weight, size and
                method of shipment. Larger and/or heavier orders are sent via UPS unless otherwise
                requested. Smaller packages are usually sent via priority mail. Back orders will be
                shipped at the same rates as regular orders. International orders require additional
                shipping fees and are usually sent via air mail. It may not be possible to insure some
                international orders. Customer is responsible for all customs duties, VAT charges,
                and similar fees, where applicable. There is an extra charge for COD orders and
                special handling. Please contact us with special requests and for more information.
            </p>
            <h6 className={'text-uppercase color-maroon'}>Retail refund policy</h6>
            <p>
                Returns will only be accepted and credit given or money refunded if ALL of the
                following conditions are met:
            </p>
            <ul>
                <li>
                    Items must be in original condition as shipped.
                </li>
                <li>
                    Notification of your intention to make a return must be received by us within ten
                    days of delivery of the item(s).
                </li>
                <li>
                    Returned item(s) must be received by us within fourteen days of the date the item
                    was delivered.
                </li>
                <li>
                    Customer is financially responsible for all shipping and handling charges
                    connected with the item, and assumes full responsibility for its safe return. We
                    suggest careful packing and insurance as the minimum precaution.
                </li>
            </ul>
            <p className={'text-primary'}>
                <i><b>Note:</b> The <b>REFUND POLICY</b> does not apply on 'in store' purchases.  For all 'in
                    store' purchases, there is <b>NO CASH REFUND</b>. We issue store credits and allow
                merchandise exchange of a same value within 14 days of the original purchase date,
                provided that the merchandise you are about to return is in its original condition.<br/>
                    Thank you.</i>
            </p>
            <h6 className={'text-uppercase color-maroon'}>Insurance and damages</h6>
            <p>
                Almost all orders are insured. If you receive an item that has been damaged during
                shipment, please contact us immediately. Save ALL packing materials and
                paperwork. If you notice external damage to the package at the time of delivery,
                alert the delivery person at that time, and, if possible, have them verify this damage
                in writing.
            </p>
            <h6 className={'text-uppercase text-center color-maroon'}>Wholesale</h6>
            <p className={'text-center'}>
                Please contact us for whole sale price.
            </p>
            <div className="color-blue text-center">
                <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/dir/40.7699456,-73.8770944/Land+Of+Buddha,+Inc.+128+Macdougal+St.+NY,+NY+-+10012,+USA/@40.7410663,-74.0084117,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x89c2599181537641:0xe484e67fa91bc594!2m2!1d-74.0002903!2d40.7300812'}>
                    Land Of Buddha, Inc.<br/>
                    128 Macdougal St.<br/>
                    NY, NY - 10012, USA
                </a><br/>
                <a title="Email Land Of Buddha" href="mailto:sales@lobny.com">
                    <i className="reg-fa fa fa-envelope-square" aria-hidden="true"></i></a>
                <a href="mailto:sales@lobny.com"> sales@lobny.com</a><br/>
                <a title="Call Land Of Buddha" href="tel:646-602-6588"><i
                    className="reg-fa fa fa-phone-square" aria-hidden="true"></i></a>
                <a href="tel:646-602-6588"> (646)-602-6588</a>
                <a href="tel:646-206-9466" className={'pl-2'}>(646)-206-9466</a>
            </div>
        </div>;
    }
}